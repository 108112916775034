<template>
  <div class="flix-flex flix-flex-container flix-flex-center">
    <a
      v-if="service"
      class="flix-btn-block flix-text-darkgrey flix-html-a"
      style="flex: 1"
      href="#"
      @click.prevent="$store.commit('service/editService', index)"
    >
      <icon id="plus" /> {{ service.title }}
    </a>
    <div
      style="flex: 1; text-align: right; font-size: 8pt; justify-content: flex-end"
      class="flix-flex flix-flex-center flix-gap-5"
    >
      <span> {{ getTags() }}</span>
      <div>
        <a
          href="#"
          class="flix-html-a flix-text-darkgrey"
          v-if="index < services.length - 1"
          @click.prevent="changePos(+1)"
          ><icon id="chevron-down"
        /></a>
        <a
          href="#"
          class="flix-html-a flix-text-darkgrey"
          v-if="index"
          @click.prevent="changePos(-1)"
          ><icon id="chevron-up"
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: Number
  },
  data() {
    return {
      services: JSON.parse(JSON.stringify(this.$store.state.service.unsaved)),
      service: false
    };
  },
  computed: {},
  mounted() {
    this.service = this.services[this.index];
  },
  methods: {
    getTags() {
      if (typeof this.service === "undefined" || !this.service) {
        return false;
      }
      return "#" + JSON.parse(JSON.stringify(this.service)).short.join(" | #");
    },
    changePos(add) {
      var index = this.index * 1;
      var services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      services.splice(index + add, 0, services.splice(index, 1)[0]);

      this.$store.commit("service/prefetch", services);
      this.$store.commit("service/editService", false);
      this.$store.commit("service/setUpdateKey");
    }
  }
};
</script>
<style lang="sass" scoped>
@media (max-width: 500px)
  .flix-flex-container
    flex-direction: column
    list-style: none
    padding: 0
</style>
